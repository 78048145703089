import { message, Spin, Table } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import { numberDecimal } from "../../../utilities/startUp";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";

const PAGE_SIZE = 10;

//let displayRobot = [];
// markup
const CommunityListing = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState({});
  let accessKey = get(props.user, "accessKey");
  //let profile = get(props.user.user, "profile");
  const mainUser = get(props, "user.user");

  useEffect(() => {
    getPairingUser(
      1,
      0,
      {
        _id: get(mainUser, "pairingUser._id"),
      },
      accessKey
    )
      .then((res) => {
        // console.log(res);
        let data = get(res, "data");
        data = data[0];
        setUser(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getData(0);
  }, [user]);

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page]);

  function getData(skip) {
    // setArrayLoading(true);
    if (!isEmpty(user)) {
      setLoading(true);

      getPairingUser(
        PAGE_SIZE,
        skip,
        {
          sponsor: get(user, "_id"),
          todaySales: true,
        },
        accessKey
      )
        .then((res) => {
          let data = get(res, "data");
          console.log("data", res);
          if (!isEmpty(data)) {
            setDataSource(data);
          }
          setTotal(get(res, "total"));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.message);
          setLoading(false);
        });
    }
  }

  const _renderList = () => {
    return (
      <div className="grid grid-cols-6 gap-2 bg-main-color-gradient rounded-3xl mx-4 pt-2 pb-1">
        <div className="col-span-2 ">
          <div className=" ">
            <div className="font-semibold text-center text-base pt-4">
              {get(user, "totalSponsor") || 0}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 今日盈利 <br /> */}
              {t("sponsorMember", sourceKey.pairing)}
            </div>
          </div>
        </div>
        <div className="col-span-2 ">
          <div className=" ">
            <div className="font-semibold text-center text-base pt-4">
              {get(user, "communitySize") || 0}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 今日盈利 <br /> */}
              {t("communityMember", sourceKey.pairing)}
            </div>
          </div>
        </div>
        {/* <div className="col-span-2 ">
          <div className=" ">
            <div className="font-semibold text-center text-base pt-4">
              {get(user, "communitySize") || 0}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {t("communityMember", sourceKey.pairing)}
            </div>
          </div>
        </div> */}

        <div className="col-span-2">
          <div className=" ">
            <div className="font-semibold text-center text-base pt-4">
              {formatDecimalNumber(
                get(user, "communitySales") || 0,
                numberDecimal
              )}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 总盈利 <br /> */}
              {t("communitySales", sourceKey.pairing)}
            </div>
          </div>
        </div>
      </div>

      // <div className="rounded-3xl m-4 bg-main-color-gradient">

      //   <div className="flex">
      //     <span className="inline-block w-1/2 text-center">
      //       <span className="text-2xl font-bold">
      //         {get(user, "totalSponsor") || 0}
      //       </span>
      //       <br />

      //       {t("sponsorMember", sourceKey.pairing)}
      //       <br />
      //     </span>
      //     <span className="inline-block w-1/2 text-center">
      //       <span className="text-2xl font-semibold">
      //         {formatDecimalNumber(get(user, "communitySales") || 0, 2)}
      //       </span>
      //       <br />

      //       {t("communitySales", sourceKey.pairing)}
      //       <br />
      //     </span>
      //   </div>
      //   <div className="flex justify-center pb-8"></div>
      // </div>
    );
  };

  const _renderTable = () => {
    let columns = [
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 用戶 <br /> */}
              {t("user", sourceKey.pairing)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "username",
        key: "username",
        align: "center",
        //render: (value) => getFormatedDate(value, true),
      },
      // {
      //   title: (
      //     <React.Fragment>
      //       <div className="text-sm  text-center">
      //         组合价值 <br />
      //         Combined Value
      //       </div>
      //     </React.Fragment>
      //   ),
      //   dataIndex: "combinedValue",
      //   key: "combinedValue",
      //   align: "center",
      //   //render: (value) => getFormatedDate(value, true),
      // },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 推荐人数 <br /> */}
              {t("sponsorMember", sourceKey.pairing)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "totalSponsor",
        key: "sponsorMember",
        align: "center",
        render: (value) => value || 0,
      },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 推荐人数 <br /> */}
              {t("communityMember", sourceKey.pairing)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "communitySize",
        key: "communityMember",
        align: "center",
        render: (value) => value || 0,
      },
      {
        title: (
          <React.Fragment>
            <div className="text-sm  text-center">
              {/* 社区业绩 <br />
              Community Sales */}
              {t("today", sourceKey.pairing)} /
              {t("communitySales", sourceKey.pairing)}
            </div>
          </React.Fragment>
        ),
        dataIndex: "communitySales",
        key: "sales",
        align: "center",
        render: (value, record) => {
          // console.log("reocrd", record);
          let communitySales = get(record, "communitySales") || 0;
          let selfTopUp = get(record, "totalSelfTopUp") || 0;
          let totalSales = communitySales + selfTopUp;
          return (
            <>
              {" "}
              {formatDecimalNumber(
                record?.todaySales || 0,
                numberDecimal
              )} / {formatDecimalNumber(totalSales, numberDecimal)}
            </>
          );
        },
      },
    ];

    return (
      <Table
        // scroll={{ x: 600 }}
        className="rounded-2xl  m-4 no-row-hover"
        size={"small"}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSize: PAGE_SIZE,
          total: total,
          simple: true,
          onChange: (page_selected) => {
            setPage(page_selected);
          },
          current: page,
          showSizeChanger: false,
        }}
      ></Table>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              showBack={true}
              suffix={
                <React.Fragment>
                  <div className="justify-end flex items-center ">
                    {/* 我的社区{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}
                    {t("myCommunity", sourceKey.pairing)}
                  </div>
                </React.Fragment>
              }
            >
              <div
                className=""
                // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
              >
                {/* <div className="pt-4">{_renderProfit()}</div> */}
                <div className="">{_renderList()}</div>
                <div className="mt-4">{_renderTable()}</div>
              </div>
            </NavHeader>
          </RequirePairingAuthWrapper>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(CommunityListing);
